import { Box, Link, Typography } from "@mui/material";
import { Img } from "react-image";
import TranslationHelper from "../../utils/TranslationHelper";
import ResizeHelper from "../../utils/ResizeHelper";
const getThumbnail = (moment) => {
  if (moment.thumbnail !== null) {
    return ResizeHelper.resizeImage(moment.thumbnail, 600);
  } else if (moment.images !== null && moment.images.length > 0) {
    return ResizeHelper.resizeImage(moment.images[0].url, 600);
  } else if (moment.videos !== null && moment.videos.length > 0) {
    return (
      moment?.videos[0]?.url
        .substring(0, moment?.videos[0]?.url.indexOf(".mp4") + 4)
        .replace(
          "https://firebasestorage.googleapis.com:443/v0/b/urbnups.appspot.com/o/spots%2F",
          "https://static.urbnups.app/spots/"
        ) + "/ik-thumbnail.jpg"
    );
  } else {
    return null;
  }
};

const SingleMomentFeed = ({ moment, language = "de" }) => {
  const momentTranslations = TranslationHelper.fetchMomentTranslations(
    moment,
    language
  );

  const thumbnail = getThumbnail(moment);

  return (
    <Box key={moment.key}>
     
        <Box
          sx={{
            position: "relative",
            flex: 1,
            width: "100%",

            overflow: "hidden",
          }}
        >
          {thumbnail !== null && (
            <Img
              src={`${thumbnail}`}
              alt={moment.name || ""}
              quality={60}
              style={{
                objectFit: "cover",
              
              }}
            />
          )}
          {thumbnail === null && (
            <Box
              style={{
                position: "relative",
                flex: 1,
                width: "100%",
                height: 200,
                backgroundColor: "#1a1a1b",
                borderRadius: 8,
              }}
            />
          )}
          <Typography variant="h6" sx={{ padding: 1 }}>
            {momentTranslations.name}
          </Typography>
          <Typography variant="subtitle2" sx={{ padding: 1 }}>
            {momentTranslations.description}
          </Typography>
        </Box>
    </Box>
  );
};

export default SingleMomentFeed;
