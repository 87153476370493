import { Box, Button, Grid, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Img } from "react-image";
import MediaGallery from "../mediaslider/MediaGallery";
import MomentsSlider from "../slider/MomentsSlider";
import { LinkButton } from "../buttons/LinkButton";
import PlaceMiniMap from "../maps/PlaceMiniMap";
import OpeningHours from "../openinghours/OpeningHours";
const extractHostname = (url) => {
  var hostname;
  //find & remove protocol (http, ftp, etc.) and get hostname

  if (url == null) return "";

  if (url.indexOf("//") > -1) {
    hostname = url.split("/")[2];
  } else {
    hostname = url.split("/")[0];
  }

  //find & remove port number
  hostname = hostname.split(":")[0];
  //find & remove "?"
  hostname = hostname.split("?")[0];

  return hostname;
};
const extractRootDomain = (url) => {
  var domain = extractHostname(url),
    splitArr = domain.split("."),
    arrLen = splitArr.length;

  //extracting the root domain here
  //if there is a subdomain
  if (arrLen > 2) {
    domain = splitArr[arrLen - 2] + "." + splitArr[arrLen - 1];
    //check to see if it's using a Country Code Top Level Domain (ccTLD) (i.e. ".me.uk")
    if (splitArr[arrLen - 2].length == 2 && splitArr[arrLen - 1].length == 2) {
      //this is using a ccTLD
      domain = splitArr[arrLen - 3] + "." + domain;
    }
  }
  return domain;
};
const SinglePlace = ({ place, onClose }) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box
        sx={{
          position: "relative",
          flex: 1,
          width: "100%",

          overflow: "hidden",
        }}
      >
        <MediaGallery media={place?.media || []} />
        <Box
          sx={{
            p: 1,
          }}
        >
          <Typography component={"div"} variant="h5">
            {place?.teaser}
          </Typography>
          <Typography component={"div"} variant="body1">
            {place?.description}
          </Typography>
          <Box
            sx={{
              display: "flex",
              mt: 1,
              mb: 1,
              gap: 1,
            }}
          >
            <Box>
              {place.links.map((link, index) => (
                <LinkButton key={index} url={link} />
              ))}
            </Box>
            {place?.mapsLink && (
              <Box>
                <Button
                  variant="filled"
                  sx={{
                    backgroundColor: "#f8f9fa",
                    color: "#000000",
                    "&:hover": {
                      backgroundColor: "#f8f9fa",
                    },
                  }}
                  startIcon={
                    <Img
                      src={`https://www.google.com/s2/favicons?domain=maps.google.com&sz=64`}
                      alt="website"
                      style={{ width: 20, height: 20, borderRadius: 20 }}
                    />
                  }
                  LinkComponent={Link}
                  href={place?.mapsLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Typography variant="subtitle2">Google Maps</Typography>
                </Button>
              </Box>
            )}
          </Box>
          <MomentsSlider
            onShowMoment={(moment) => {
              console.log("show moment", moment);
              var eventData = {
                type: "urbnups-show-moment",
                data: moment,
              };
              window.top.postMessage(eventData);
            }}
            moments={place?.moments || []}
          />
        </Box>
        <OpeningHours openingHours={place?.openingHours} />
        <Typography variant="h6">{place?.formattedAddress}</Typography>
        <PlaceMiniMap title={place.name} location={place?.location} />
      </Box>
    </Box>
  );
};

export default SinglePlace;
