import { Box } from "@mui/material";
import { Img } from "react-image";
import CopyrightTooltip from "../copyright/CopyrightTooltip";
import ResizeHelper from "../../utils/ResizeHelper";
import LinkButton from "../buttons/LinkButton";
const getImageCopyRight = (media) => {
  var copyright = "";
  if (media?.source != null && media?.source.length > 0) {
    copyright +=
      media?.source.trim() +
      " " +
      (media?.license != null ? "(" + media?.license.trim() + ")" : "");
  } else if (
    media?.googleMapsAuthorAttributions != null &&
    media?.googleMapsAuthorAttributions.length > 0
  ) {
    console.log(media?.googleMapsAuthorAttributions);
    copyright = media?.googleMapsAuthorAttributions
      .map((attribution, index) => {
        console.log(attribution);
        console.log("is google maps image");
        return attribution.displayName + " (Google Maps)";
      })
      .join(", ");
  }

  return copyright;
};

const MediaGalleryItem = ({
  item,
  index,
  onClick = () => {},
  variant = "thumbnail",
}) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        display: "flex",
        scrollSnapAlign: "start",
        width: "100%",
        height: "100%",

        flexShrink: 0,
        position: "relative",
      }}
    >
      {item?.type === "image" && (
        <Img
          src={ResizeHelper.resizeImage(
            item?.url,
            variant == "thumbnail" ? 200 : 600
          )}
          alt={item?.alt || ""}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
      )}
      {variant == "hero" && (
        <>
          {((item?.source != null && item?.source.length > 0) ||
            (item?.googleMapsAuthorAttributions != null &&
              item?.googleMapsAuthorAttributions.length > 0)) && (
            <Box
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                with: "100%",
              }}
            >
              <CopyrightTooltip text={getImageCopyRight(item)} />
            </Box>
          )}
          {item?.link!=null && (
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                right: 0,
                padding: 1,
                color: "white",
              }}
            >
              <LinkButton url={item.link} />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default MediaGalleryItem;
