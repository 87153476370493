import SearchIcon from "@mui/icons-material/Search";
import SendIcon from "@mui/icons-material/Send";
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { useState } from "react";
import POIQuestion from "./POIQuestion";
const POIQuestionCTA = ({
  questions = [],
  placeholder,
  headline,
  subline,
  place = null,
}) => {
  const [search, setSearch] = useState("");

  const onSendChatMessage = (message, audio = false) => {
    window.postMessage(
      {
        type: "urbnups-chat-show",
        data: null,
      },
      "*"
    );
    console.log("window urbnups", window.urbnups);

    window.postMessage(
      {
        type: "urbnups-chat-sendMessage",
        data: {
          message: "Ich habe folgende Frage zu "+ place?.name+" bei "+place?.formattedAddress + ": " + message,
          audio: audio,
        },
      },
      "*"
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        p: 4,
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "600px",
          backgroundColor: "#E3F5F9",
          padding: "20px",
          borderRadius: "15px",
          textAlign: "center",
        }}
      >
        <Typography
          
          sx={{ marginBottom: "20px", textAlign: "center" }}
        >
          {headline}
        </Typography>
        <Typography
          variant="h5"
          sx={{ marginBottom: "20px", textAlign: "center" }}
        >
          {subline}
        </Typography>

        {/* Search Input */}
        <TextField
          fullWidth
          value={search}
          onKeyDown={(ev) => {
            if (ev.key === "Enter") {
              // Do code here
              ev.preventDefault();
              onSendChatMessage(search);
              setSearch("");
            }
          }}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={placeholder}
          InputProps={{
            startAdornment: <SearchIcon />,
            endAdornment: (
              <Button
                variant="contained"
                color="primary"
                sx={{
                  borderRadius: "30px",
                }}
                onClick={() => {
                  onSendChatMessage(search);
                  setSearch("");
                }}
              >
                <SendIcon />
              </Button>
            ),
          }}
          sx={{
            marginBottom: "20px",
            backgroundColor: "#fff",
            borderRadius: "5px",
          }}
        />

        {/* Questions List */}
        <Stack direction="row" flexWrap="wrap" gap={1}>
          {questions.map((question, index) => (
            <POIQuestion
              key={index}
              question={question}
              onClick={onSendChatMessage}
            />
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

export default POIQuestionCTA;
