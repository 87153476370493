
const TranslationHelper = {
  formatLocationString:  function (geoContexts,language, showFullLocation = false,onlyField=null) {
  
    if (geoContexts === null) return "";
  
  
    var geoContext=geoContexts[language]
  
    if (geoContext === null) {
      //fallback to English
      if(geoContexts["en"]!==null)
      geoContext=geoContexts["en"]
      else
      geoContext=geoContexts[Object.keys(geoContexts)[0]]

    }
  
    var locationString=""
  
  
    if(geoContext.city !== null)
    locationString=geoContext.city
  
    if(geoContext.region !== null&&geoContext.region.length>0&&showFullLocation) 
    {
      if(locationString.length>0)
      locationString+=", "
      locationString+=geoContext.region
    }
    if(geoContext.country !== null&&showFullLocation)  
    {
      if(locationString.length>0)
      locationString+=", "
      locationString+=geoContext.country
    }

    if(onlyField!==null)
    {
      if(onlyField==="city")
      return geoContext.city
      if(onlyField==="region")
      return geoContext.region
      if(onlyField==="country")
      return geoContext.country
    }
  
    return locationString
  },
  fetchMomentTranslations:  function(spot,targetLanguage)
  {
    var name=""
    var description=""

    if(spot?.translations===null)
   {
    name=spot.name
    description=spot.description
    return {name,description}
   }
   if(spot?.language===targetLanguage)
   {
    return {
      name:spot?.name,
      description:spot?.description
    }
   }
    var translation=spot?.translations.find((translation)=>translation.language.toLowerCase()===targetLanguage.toLowerCase())

    if(translation)
    {
      name=translation.name
      description=translation.description
      return {name,description}
    }
    else
    {
        name=spot.name
        description=spot.description
        return {name,description}
      
    }

    
    
  },
    fetchTipTranslations:  function(tip,targetLanguage)
  {
    var headline=""
    var description=""
    var subline=""
    var metaDescription=""
    var metaTitle=""
    
  

    if(tip?.translations===null||tip?.language===targetLanguage)
   {
    headline=tip.headline
    description=tip.description
    subline=tip.subline
    metaDescription=tip.metaDescription
    metaTitle=tip.metaTitle
   
   } else{



    var translation=tip?.translations[targetLanguage.toLowerCase()]
    console.log("translation tip "+targetLanguage,translation)
    if(translation)
    {
      headline=translation.headline
      description=translation.description
      subline=translation.subline
      metaDescription=translation.metaDescription
      metaTitle=translation.metaTitle
     
    }
    
     
      
    }

    return {
     headline,description,subline,metaDescription,metaTitle
    }
    
  },
  fetchCollectionTranslation:  function(collection,targetLanguage)
  {
    var name=""
    var description=""

    if(collection?.translations===null)
   {
    name=collection.name
    description=collection.description
    return {name,description}
   }
    var translation=collection?.translations.find((translation)=>translation.language.toLowerCase()===targetLanguage.toLowerCase())

    if(translation)
    {
      name=translation.name
      description=translation.description
      return {name,description}
    }
    else
    {
        name=collection.name
        description=collection.description
        return {name,description}
      
    }

    
    
  },

  fetchBannerTranslation:  function(banner,targetLanguage)
  {
    var headline=""
    var subline=""

    if(banner?.headline!==null&&banner?.headline[targetLanguage]!==null)
    {
      headline=banner?.headline[targetLanguage]
    }
    if(banner?.subline!==null&&Array.isArray(banner?.subline)&&banner?.subline[targetLanguage]!==null)
    {
      subline=banner?.subline[targetLanguage]
    }

    if(headline.length===0&&banner?.translations!==null)
    {
      var translation=banner?.translations.find((translation)=>translation.language.toLowerCase()===targetLanguage.toLowerCase())
      if(translation?.headline!==null&&translation?.headline.length>0)
      {
        headline=translation?.headline
      }
      if(translation?.subline!==null&&translation?.subline.length>0)
      {
        subline=translation?.subline
      }
    }

    if(headline.length===0)
    {
      Object().keys(banner?.headline).forEach((key)=>{
        if(headline.length===0)
        headline=banner?.headline[key]
      })
      Object().keys(banner?.subline).forEach((key)=>{
        if(subline.length===0)
        subline=banner?.subline[key]
      })

    }

    return {headline,subline}
    
  }

}
 

export default TranslationHelper;
