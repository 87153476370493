import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  IconButton,
  Modal,
  Slide,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useEffect, useState } from "react";
import ChatIframe from "./ChatIframe";
import i18n from "../../i18n";

const ChatWidget = ({ logo }) => {
  const url = window.urbnups.chatUrl;
  const [loaded, setLoaded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [showInfo, setShowInfo] = useLocalStorage("urbnups_showChatCTAInfo", true);
  const userId = window.urbnups.userId;
  const [cta, setCta] = useState(null);


  useEffect(() => {
    if(i18n?.language!=null){
      var lang=i18n.language;
      if(window.urbnups.chatCTA[lang]!=null)
      setCta(window.urbnups.chatCTA[lang])
      else if(window.urbnups.chatCTA["en"]!=null)
      setCta(window.urbnups.chatCTA["en"])
      
      
    }
  }, [i18n]);
  const toggleChat = () => {
    if (!loaded) {
      setLoaded(true);
      setShowInfo(false);
    }
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleMessage = (ev) => {
      const event = ev.data;
      if (event.type === "urbnups-chat-hide") {
        setIsOpen(false);
      }
      if (event.type === "urbnups-chat-show") {
        setIsOpen(true);
      }
    };

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, []);
console.log("showInfo",showInfo)
  return (
    <Box>
      {!isOpen && (
        <Box
          sx={{
            position: "fixed",
            bottom: 12,
            right: 12,
            zIndex: 1000,
            justifyContent: "center",
            alignItems: "center",
          
          }}
        >
          {cta != null && (
            <Box
              sx={{
                backgroundColor: "#f0f0f0",
                borderRadius: 4,
                paddingLeft: 2,
                paddingRight: 2,
                paddingTop: 2,
                paddingBottom: 2,
                position: "absolute",
                display: showInfo !==false? "block" : "none",
                bottom: 30,
                right: 50,
                width:{
                  xs: "200px",
                  md:  "250px",
                },
                borderRadius: "16px",
                wordBreak: "break-word",
            
                border: "solid 1px #eee",
                boxShadow: " 0 3px 15px #00000026",
                
              }}
            >
              <CloseIcon
                onClick={() => {
                  setShowInfo(false);
                }}
                sx={{
                  color: "black",
                  position: "absolute",
                  right: 5,
                  top: 5,
                }}
              />

              <Typography variant="body1" sx={{ color: "black" }}>
               {cta}
              </Typography>
            </Box>
          )}

          <IconButton
            color="secondary"
            onClick={toggleChat}
            sx={{ position: "absolute", bottom: 0, right: 0, p: 1 }}
          >
            <img
              alt="Chatbot Logo"
              src={logo}
              style={{ width: 60, height: 60 }}
            />
          </IconButton>
        </Box>
      )}
      {loaded && isDesktop ? (
        <Box
        className="urbnups-chat-widget-desktop"
          sx={{
            position: "fixed",
            bottom: 12,
            right: 12,
          }}
        >
          <Slide direction="up" in={isOpen} mountOnEnter>
            <Box
              sx={{
                position: "absolute",
                bottom: { xs: 0, md: 12 },
                right: { xs: 0, md: 12 },
                maxHeight: { xs: "100vh", md: "80vh" },
                width: { xs: "100%", md: "450px" },
                height: { xs: "100%", md: "600px" },
                background: "white",

                borderRadius: 2,
                border: "1px solid white",
                p: 0,
                boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                overflow: "hidden",
              }}
            >
              <ChatIframe url={url} userId={userId} />
            </Box>
          </Slide>
        </Box>
      ) : (
        <Modal
          open={isOpen}
          keepMounted
          className="urbnups-chat-modal"
          onClose={() => {
            setIsOpen(false);
          }}
          sx={{}}
        >
          <Box
            sx={{
              width: "100%",
              height: "100dvh",
              position: "relative",
            }}
          >
            <ChatIframe url={url} userId={userId} />
          </Box>
        </Modal>
      )}
    </Box>
  );
};

export default ChatWidget;
