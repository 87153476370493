import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const POIDescription = ({ place }) => {
  const [expanded, setExpanded] = useState(!place?.urbnupsMoments||place?.urbnupsMoments.length===0);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Box
      onClick={handleExpandClick}
      sx={{
        height: expanded ? "auto" : place?.description?"100px":"auto", // '120px' when collapsed, 'auto' when expanded
        overflow: "hidden", // Hide overflow when collapsed
        transition: "height 2s ease", // Smooth height transition
        p: 2,
      }}
    >
       <Box>
      <Box sx={{ display: "flex", alignItems: "center",flexDirection:"column",position:"relative" }}>
         <Typography
          variant="h4"
          sx={{ textAlign: "center", flexGrow: 1 }}
          component="div"
        >
          {place.name}
        </Typography>
        <Typography
         
          sx={{ textAlign: "center"}}
          
        >
          {place.teaser}
        </Typography>
       
       
       
        {place?.description && (
          
          <ExpandMore
           sx={{
            position:"absolute",
            right:10,
            bottom:0,
           }}
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        )}
        </Box>
       
      </Box>

      {/* Show full or partial description based on expanded state */}
      {place?.description && (
        <Typography
          sx={{
            pb: 4,
            pt:1
          }}
        >
          {place.description}
        </Typography>
      )}
    </Box>
  );
};

export default POIDescription;
