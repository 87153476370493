import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useFetch } from "use-http";
import SinglePlace from "./components/place/SinglePlace";
const options = {}; // these options accept all native `fetch` options
export const SinglePlaceModal = ({}) => {
  const partnerId = window.urbnups.partnerId;
  const language = window.urbnups.language;
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [modalOpen, setModalOpen] = useState(false);
  const [placeId, setPlaceId] = useState(null);
  const [place, setPlace] = useState(null);

  const { get, loading, data, error } = useFetch(
    "https://urbnups.com/widgets.api/v2/place"
  );

  const onClose = () => {
    setModalOpen(false);
    //setPlaceId(null);
  };

  useEffect(() => {
    if (data) {
      console.log("data", data);
      setPlace(data);
    }
  }, [data]);

  const handlePopState = (event) => {
    //alert("handlePopState");
    setModalOpen(false);
  };

  useEffect(() => {
    window.addEventListener("message", (ev) => {
      const event = ev.data;

      if (event.type === "urbnups-show-place") {
        console.log("receive urbnups-show-place", event.data);

        setPlaceId(event.data?.placeId);
        window.history.pushState({ modalOpen: true }, "", window.location.href);
        get(`${event.data?.placeId}?lang=${language}&partnerId=${partnerId}`);
        setModalOpen(true);
        window.addEventListener("popstate", handlePopState);
      }
    });

    return () => {
      window.removeEventListener("message", () => {});
    };
  }, []);

  console.log("fetched place", place);
  console.log("data?.place_id}", place?.place_id);
  return (
    <Dialog
      open={modalOpen}
      fullScreen={!isDesktop}
      maxWidth="md"
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogContent
        sx={{
          p: 0,
          width: isDesktop ? "900px" : "100%",
          height: isDesktop ? "900px" : "100%",
          flexGrow: 1,
        }}
      >
        {" "}
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            
            p:1,
            zIndex: 100,
            //align vertical
          }}
        >
          {onClose && (
            <Box>
            <IconButton className="urbnups-backButton" onClick={onClose}>
              <ArrowBackIosNewIcon />
            </IconButton>
            </Box>
             
          )}
           <Typography component={"div"} sx={{
            pl:1,
           }} variant="h4">
              {place?.name}
            </Typography>
        </Box>
        {loading && (
          <>
            <Box
              sx={{
                padding: 10,
              }}
            >
              <CircularProgress className="urbnups-loadingindicator" />
            </Box>
          </>
        )}
        {error && "Error!"}
        {!loading && !error && place != null && (
          <SinglePlace
            key={place?.place_id}
            place={place}
            language={language}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
