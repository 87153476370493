import { Button, Typography } from "@mui/material";
import React, { useState } from "react";
import {Img} from "react-image";
import BrowserModal from "../browser/BrowserModal";

const extractHostname = (url) => {
  var hostname;
  //find & remove protocol (http, ftp, etc.) and get hostname

  if (url == null) return "";

  if (url.indexOf("//") > -1) {
    hostname = url.split("/")[2];
  } else {
    hostname = url.split("/")[0];
  }

  //find & remove port number
  hostname = hostname.split(":")[0];
  //find & remove "?"
  hostname = hostname.split("?")[0];

  return hostname;
};
const extractRootDomain = (url) => {
  var domain = extractHostname(url),
    splitArr = domain.split("."),
    arrLen = splitArr.length;

  //extracting the root domain here
  //if there is a subdomain
  if (arrLen > 2) {
    domain = splitArr[arrLen - 2] + "." + splitArr[arrLen - 1];
    //check to see if it's using a Country Code Top Level Domain (ccTLD) (i.e. ".me.uk")
    if (splitArr[arrLen - 2].length == 2 && splitArr[arrLen - 1].length == 2) {
      //this is using a ccTLD
      domain = splitArr[arrLen - 3] + "." + domain;
    }
  }
  return domain;
};

export const LinkButton = ({ url }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = (event) => {
    event.preventDefault();
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  return (
    <>
      <Button
        variant="filled"
        sx={{
          backgroundColor: "#f8f9fa",
          color: "#000000",
          "&:hover": {
            backgroundColor: "#f8f9fa",
          },
        }}
        startIcon={<Img
            src={`https://www.google.com/s2/favicons?domain=${extractRootDomain(
              url
            )}&sz=64`}
            alt="website"
            style={{ width: 20, height: 20, borderRadius: 20 }}
          />
        }
        onClick={handleOpen}
      >
        <Typography variant="subtitle2">{extractRootDomain(url)}</Typography>
      </Button>
      <BrowserModal open={open} title={extractRootDomain(url)} handleClose={handleClose} iframeSrc={url} />
    </>
  );
};

export default LinkButton;
