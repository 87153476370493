import { Box } from "@mui/material";
import React from "react";
import POIQuestionCTA from "./POIQuestionCTA";

const POIQuestions = ({ place }) => {
  return (
    <Box>
      <POIQuestionCTA
        place={place}
      
        headline={"Du hast Fragen zu "+place?.name+"? Gerne beantwortet dir der digitale Reisassitent deine Frage."}
        placeholder={""}
        questions={place?.questions}
      />
    </Box>
  );
};

export default POIQuestions;
