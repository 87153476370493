import { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Chip,
  Stack,
  Grid,
  Typography,
  Container,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import SearchIcon from "@mui/icons-material/Search";
const ChatCTA = ({ questions, placeholder, headline, regionName = null }) => {
  const [search, setSearch] = useState("");

  const onSendChatMessage = (message, audio = false) => {
    window.postMessage(
      {
        type: "urbnups-chat-show",
        data: null,
      },
      "*"
    );
    console.log("window urbnups", window.urbnups);
    
    window.postMessage(
      {
        type: "urbnups-chat-sendMessage",
        data: {
          message:  message,
          audio: audio,
        },
      },
      "*"
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        p: 4,
      }}
    >
      <Box
        sx={{
          maxWidth: "600px",
          width: "100%",

          backgroundColor: "#E3F5F9",
          padding: "20px",
          borderRadius: "15px",
          textAlign: "center",
        }}
      >
        <Typography
          className="cta-headline"
          sx={{ marginBottom: "20px", textAlign: "center" }}
        >
          {headline}
        </Typography>

        {/* Search Input */}
        <TextField
          fullWidth
          
          value={search}
          onKeyDown={(ev) => {
            if (ev.key === "Enter") {
              // Do code here
              ev.preventDefault();
              onSendChatMessage(search);
            }
          }}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={placeholder}
          InputProps={{
            startAdornment: <SearchIcon />,
            endAdornment: (
              <Button
                variant="contained"
                color="primary"
                sx={{
                  borderRadius: "30px",
                }}
                onClick={() => {
                  onSendChatMessage(regionName ? regionName + ": " +search: search);
                  setSearch("");
                }}
              >
                <SendIcon sx={{
                  color: "white"
                }} />
              </Button>
            ),
          }}
          sx={{
            marginBottom: "20px",
            backgroundColor: "#fff",
            borderRadius: "5px",
            border:"none"
          }}
        />

        {/* Questions List */}
        <Stack direction="row" flexWrap="wrap" justifyContent="center" gap={1}>
          {questions.map((question, index) => (
            <Chip
              onClick={() => {
                onSendChatMessage(question, false);
              }}
              key={index}
              label={question}
              sx={{
                backgroundColor: "#f3f7fc",
                color: "#004A7C",
                borderRadius: "5px",
                textWrap: "wrap",
                height: 'auto',
    '& .MuiChip-label': {
      display: 'block',
      whiteSpace: 'normal',
    },

                padding: "10px",
                "&:hover": {
                  backgroundColor: "#dbe8f4",
                },
              }}
            />
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

export default ChatCTA;
