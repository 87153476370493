import r2wc from "@r2wc/react-to-web-component";
import { RudderAnalytics } from "@rudderstack/analytics-js";
import { v4 as uuidv4 } from "uuid";
import ChatWidgetApp from "./ChatWidgetApp";
import { RegionMomentsSliderApp } from "./RegionMomentsSliderApp";
import { SingleMomentModal } from "./SingleMomentModal";
import { SinglePlaceModal } from "./SinglePlaceModal";
import RegionOverview from "./components/region/overview/RegionOverview";
import RegionsSlider from "./components/slider/RegionsSlider";
import "./i18n";
import { RegionModal } from "./RegionModal";
const ChatWidget = r2wc(ChatWidgetApp, {
  props: {
    partnerid: "string",
    regionid: "string",
    language: "string",
    logo: "string",
  },
});

const RegionMomentsSlider = r2wc(RegionMomentsSliderApp, {
  props: {
    partnerid: "string",
    regionid: "string",
    headline: "string",
    subline: "string",
    morelabel: "string",
    morelink: "string",
  },
});

/*
const RegionFeed = r2wc(RegionFeedApp, {
  props: {
    regionid: "string",
    language: "string",
    headline: "string",
    subline: "string",
    morelabel: "string",
    morelink: "string",
  },
});
*/
const RegionOverviewApp = r2wc(RegionOverview, {
  props: {
    regionid: "string",
  },
});
const RegionSliderApp = r2wc(RegionsSlider, {
  props: {
    parentregionid: "string",
    language: "string",
    headline: "string",
    subline: "string",
    morelabel: "string",
    morelink: "string",
    baseurl: "string",
  },
});

const SingleMomentModalApp = r2wc(SingleMomentModal, {
  props: {},
});

const SinglePlaceModalApp = r2wc(SinglePlaceModal, {
  props: {
    partnerid: "string",
    regionid: "string",
    language: "string",
    headline: "string",
    subline: "string",
    morelabel: "string",
    morelink: "string",
  },
});

const RegionModalApp = r2wc(RegionModal, {
  
});


if (window?.urbnupsanalytics == undefined) {
  const analyticsInstance = new RudderAnalytics();

  analyticsInstance.load(
    "2ZAIONkTxs1iTl9AIRcbmug35Jj",
    "https://urbnupsjohoyu.dataplane.rudderstack.com"
  );

  analyticsInstance.ready(() => {
    console.log("urbnups widget Analytics We are all set!!!");
    window.urbnupsanalytics = analyticsInstance;
    window.urbnups.userId = analyticsInstance.getAnonymousId();

    //check if user has a session id
    if (localStorage.getItem("urbnups_sessionId") == null) {
      const sessionId = uuidv4();
      window.urbnups.sessionId = sessionId;
      localStorage.setItem(
        "urbnups_sessionId",
       window.urbnups.sessionId
      );
    } else {
      window.urbnups.sessionId = localStorage.getItem("urbnups_sessionId");
    }

  
    customElements.define("urbnups-chatwidget", ChatWidget);
    customElements.define("urbnups-region-momentsslider", RegionMomentsSlider);

   // customElements.define("urbnups-region-feed", RegionFeed);

   const regionOverview = document.querySelector('urbnups-region-overview');
   if (regionOverview && regionOverview.previousElementSibling) {
     const previousElement = regionOverview.previousElementSibling;
     if (previousElement.tagName.toLowerCase() === 'h1') {
       previousElement.remove();
     }
   }
    customElements.define("urbnups-region-overview", RegionOverviewApp);

    customElements.define("urbnups-region-slider", RegionSliderApp);

    customElements.define("urbnups-place-modal", SinglePlaceModalApp);

    customElements.define("urbnups-moment-modal", SingleMomentModalApp);

    customElements.define("urbnups-region-modal", RegionModalApp);



          //add modals to dom
      const modalContainer = document.createElement("urbnups-moment-modal");
      document.body.appendChild(modalContainer);

      const placeModalContainer = document.createElement("urbnups-place-modal");
      document.body.appendChild(placeModalContainer);

      const regionModalContainer = document.createElement("urbnups-region-modal");
      document.body.appendChild(regionModalContainer);
  });
}
