import { Box, CircularProgress, Typography } from "@mui/material";
import { useState } from "react";
import { useFetch } from "use-http";
import ChatCTA from "../../chat/ChatCTA";
import POISlider from "../../slider/POISlider";
import { RegionFeedModal } from "../feed/RegionFeedModal";

import { useDocumentTitle } from "@uidotdev/usehooks";
import SinglePOISlider from "../../poi/SinglePOISlider";
import SinglePOIFeed from "../../poi/SinglePOIFeed";
import RegionPersonalizeCTA from "../../personalization/RegionPersonalizeCTA";
const partnerId = window.urbnups.partnerId;

const language = window.urbnups.language;
const url = "http://localhost:4000/#/amoos?id=101"; // We can use 'window.location'

function getUrlParam(q) {
  var url = window.location.href;
  return (url.match(new RegExp("[?&]" + q + "=([^&]+)")) || [, null])[1];
}
const RegionFeed = ({region, regionid, selectedCategory,setSelectedCategory }) => {
  const userId = window.urbnups.userId;
  const sessionId = window.urbnups.sessionId;

  const {
    loading,
    get,
    error,
    data = { region: null, feed: [] },
  } = useFetch(
    `https://urbnups.com/widgets.api/region/${regionid}/feed?lang=${language}&partnerId=${partnerId}&userId=${userId}${
      sessionId ? `&sessionId=${sessionId}` : ""
    }${selectedCategory ? `&categoryId=${selectedCategory}` : ""}`,

    [regionid, language, partnerId, selectedCategory]
  );
  useDocumentTitle(region?.name);

  const [showRegionFeedModal, setShowRegionFeedModal] = useState(false);
  const [regionFeedModalCategory, setRegionFeedModalCategory] = useState(null);
  //struktur der Seite
  // Banner aus der region

  //Slider wie Linz App  mit Mischung zurüch Highlights / Für Dich
  //Alle Anzeigen ist geil bei Zürich App in Liste oder Map
  //zürich hat auch eine suche

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        rowGap: 3,
      }}
    >
      {loading && <CircularProgress />}
      <Box
        sx={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
          gap: 2,
        }}
      >
        {data?.feed != null &&
          data?.feed.length > 0 &&
          data?.feed.map((item, index) => {
            if (item?.itemType == "poislider")
              return (
              
                  <Box
                  key={item.key}
                    sx={{
                      gridColumn: "1 / -1",
                      
                    }}
               >
                  <POISlider
                    pois={item?.pois}
                    headline={item.headline}
                    subline={item.subline}
                    id={item.key}
                    onShowPOI={(poi) => {
                      console.log("onShowPOI", poi);
                      window.postMessage(
                        {
                          type: "urbnups-show-place",
                          data: poi,
                        },
                        "*"
                      );
                    }}
                    showMore={
                      item?.categoryId!=null
                    }
                    moreLabel="Mehr"
                    onMore={() => {
                      /*
                      setShowRegionFeedModal(true);
                      setRegionFeedModalCategory(null);
                      */
                     if(item?.categoryId!=null) {
                      setSelectedCategory(item?.categoryId);
                      //window scroll to top smooth
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                     }

                      

                    }}
                  /></Box>
          
              );
            if (item?.itemType == "poi") {
              return (
                <SinglePOIFeed
                  onShowPOI={(poi) => {
                    console.log("onShowPOI", poi);
                    window.postMessage(
                      {
                        type: "urbnups-show-place",
                        data: poi,
                      },
                      "*"
                    );
                  }}
                  key={item?.key}
                  poi={item}
                />
              );
            }
            if(item?.itemType == "headline") {
              return (
                <Box
                key={item.key}
                    sx={{
                      gridColumn: "1 / -1",
                      
                    }}
               >
               <Typography className="region-feed headline">{item.headline}</Typography>
               {item.subline &&
               <Typography className="region-feed subline" >{item.subline}</Typography>
               }
               </Box>
              );
            }
            if(item?.itemType=="personalizecta")
            {
              return (
               <RegionPersonalizeCTA/>
              );
            }
          })}
      </Box>

      <ChatCTA
        headline={
          "Finde die besten Ausflugsziele für deine Reise nach " +
          region?.name
        }
        regionName={region?.name}
        placeholder={"Aktivitäten bei Regen für Kinder"}
        questions={[
          `Welche Sehenswürdigkeiten sollte ich in ${region?.name} nicht verpassen?`,
          `Was sind die besten Restaurants in ${region?.name}?`,
          `Was sind die besten Aktivitäten in ${region?.name} für Kinder?`,
          `Welche kulinarischen Highlights gibt es in ${region?.name}?`,
        ]}
      />
         <RegionPersonalizeCTA/>
      {showRegionFeedModal && (
        <RegionFeedModal
          categories={data?.categories}
          currentCategoryId={regionFeedModalCategory}
          setCurrentCategoryId={setRegionFeedModalCategory}
          regionid={regionid}
          modalOpen={showRegionFeedModal}
          onClose={() => {
            setShowRegionFeedModal(false);
          }}
        />
      )}
    </Box>
  );
};

export default RegionFeed;
