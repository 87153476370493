import CloseIcon from "@mui/icons-material/Close";
import {
    Box,
    Dialog,
    DialogTitle,
    IconButton,
    Typography
} from "@mui/material";
import React from "react";

const BrowserModal = ({ open,title="", handleClose, iframeSrc }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      fullScreen={true}
      aria-labelledby="iframe-modal-title"
      aria-describedby="iframe-modal-description"
      sx={{
        
      }}
    >
      <DialogTitle sx={{
        p:1,
        m:0,
        borderBottom: "1px solid #e0e0e0",
        display: "flex",

      }}>
        <Box sx={{ display: "flex",
         
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
         }}>
          <IconButton  className="urbnups-backButton"  onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <Typography variant="body1" sx={{pl:1 }}>
            {title}
          </Typography>
        </Box>
      </DialogTitle>
      <Box
        sx={{
         
          height: "100vh",
          width: "100vw",
          position: "relative",
          display: "flex",
          justifyContent: "center",

        }}
      >
        <iframe
          src={iframeSrc}
          title="Iframe Modal"
          width="100%"
          height="100%"
          style={{ border: "none", borderRadius: "8px" }}
        />
      </Box>
    </Dialog>
  );
};
export default BrowserModal;
