import { Box, CircularProgress } from "@mui/material";
import { useState } from "react";
import { useFetch } from "use-http";
import RegionBanner from "../banner/RegionBanner";
import { RegionFeedModal } from "../feed/RegionFeedModal";

import { useDocumentTitle } from "@uidotdev/usehooks";
import RegionFeed from "../feed/RegionFeed";
import { PersonalizeRegionModal } from "../../personalization/PersonalizeRegionModal";
const partnerId = window.urbnups.partnerId;

const language = window.urbnups.language;
const url = "http://localhost:4000/#/amoos?id=101"; // We can use 'window.location'

function getUrlParam(q) {
  var url = window.location.href;
  return (url.match(new RegExp("[?&]" + q + "=([^&]+)")) || [, null])[1];
}
const RegionOverview = ({ regionid: regionIdParam = null }) => {
  const regionid = regionIdParam || getUrlParam("regionid");
  
  const userId = window.urbnups.userId;
  const sessionId = window.urbnups.sessionId;
  const [selectedCategory, setSelectedCategory] = useState("explore");

  

  const {
    loading,
    get,
    error,
    data = { region: null, feed: [] },
  } = useFetch(
    `https://urbnups.com/widgets.api/region/${regionid}/overview?lang=${language}&partnerId=${partnerId}&userId=${userId}${
      sessionId ? `&sessionId=${sessionId}` : ""
    }`,

    [regionid, language, partnerId]
  );
  useDocumentTitle(data?.region?.name);

  const [showRegionFeedModal, setShowRegionFeedModal] = useState(false);
  const [regionFeedModalCategory, setRegionFeedModalCategory] = useState(null);
  //struktur der Seite
  // Banner aus der region

  //Slider wie Linz App  mit Mischung zurüch Highlights / Für Dich
  //Alle Anzeigen ist geil bei Zürich App in Liste oder Map
  //zürich hat auch eine suche

  console.log("data", data);

  if (data?.region == null) {
    return null;
  }

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        rowGap: 3,
      }}
    >
      <RegionBanner
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        categories={data?.categories}
        region={data?.region}
      />


      {loading && <CircularProgress />}
      <RegionFeed
        region={data?.region}
        regionid={regionid}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />

      {showRegionFeedModal && (
        <RegionFeedModal
          categories={data?.categories}
          currentCategoryId={regionFeedModalCategory}
          setCurrentCategoryId={setRegionFeedModalCategory}
          regionid={regionid}
          modalOpen={showRegionFeedModal}
          onClose={() => {
            setShowRegionFeedModal(false);
          }}
        />
      )}
      <PersonalizeRegionModal regionid={regionid} />
    </Box>
  );
};

export default RegionOverview;
