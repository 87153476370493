import React, { useState } from "react";
import { Card, CardContent, Typography, IconButton, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { Img } from "react-image";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const POIHighlightsSlider = ({ place }) => {
  const [expanded, setExpanded] = useState(false);

  if (!place?.urbnupsMoments || place?.urbnupsMoments.length === 0) {
    return null;
  }

  return (
    <Box
      sx={{
        backgroundColor: "#c7354c",
        borderRadius: 4,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        boxShadow: "0px -8px 20px rgba(0, 0, 0, 0.4)", // Shadow only on the top
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        padding: 4,
        position: "relative",
      }}
    >
      <Box
        sx={{
          height: "auto", // '120px' when collapsed, 'auto' when expanded
          overflow: "hidden", // Hide overflow when collapsed
          transition: "height 2s ease", // Smooth height transition

          color: "white",
        }}
      >
        <Typography
          variant="h5"
          sx={{ textAlign: "center", flexGrow: 1, pb: 2 }}
          component="div"
        >
          Highlights
        </Typography>
        {place?.urbnupsMoments.map((moment, index) => (
          <Box key={index}>
            <Typography
              variant="h4"
              sx={{ textAlign: "center", flexGrow: 1, pb: 2 }}
            >
              {moment.name}
            </Typography>
            {moment?.thumbnail && (
              <LazyLoadImage
                src={`${moment.thumbnail}`}
                alt={moment.name || ""}
                quality={60}
                style={{
                  objectFit: "cover",
                  objectPosition: "center",

                  width: "100%",
                  maxHeight: 300,
                  borderRadius: 8,
                }}
              />
            )}
            <Typography
              sx={{
                pb: 4,
                pt:2,
              }}
            >
              {moment.description}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default POIHighlightsSlider;
