import { Box, CircularProgress } from "@mui/material";
import { useMemo } from "react";

const ChatIframe = ({ url, userId }) => {
  // Cache the iframe URL using useMemo
  const iframeUrl = useMemo(() => {
    //check if url has already query parameters
    if(url.indexOf("?") > -1){
    return userId ? `${url}&userId=${userId}` : null;
    }
    return userId ? `${url}?userId=${userId}` : null;
  }, [url, userId]);

  if (!iframeUrl) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
    <CircularProgress className='urbnups-loadingindicator'/>
      </Box>
    );
  }

  return (
    <iframe
      title="Chat Window"
      allow="microphone"
      src={iframeUrl}
      style={{ width: "100%", height: "100%", border: "none" }}
    />
  );
};

export default ChatIframe;