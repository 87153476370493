import { Box, Dialog, DialogContent } from "@mui/material";
import "./embla.css";

import React from "react";
import { Img } from "react-image";
import LinkButton from "../buttons/LinkButton";
import ResizeHelper from "../../utils/ResizeHelper";

const MediaGalleryModal = ({ media, show, index, onClose }) => {
  if (media == null) return null;

  return (
    <Dialog  fullWidth={true} maxWidth="md" onClose={onClose} open={show}>
      
        <Img
          src={ResizeHelper.resizeImage(media.url, 900)}
          alt={media.title}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
        {media?.link!=null&&
        <Box sx={{
          position: "absolute",
          bottom: 0,
          right: 0,
          padding: 1,
          color: "white",
        }}>
        <LinkButton url={media.link}  />
        </Box>}
    </Dialog>
  );
};

export default MediaGalleryModal;
