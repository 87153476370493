import { Box, Typography } from "@mui/material";
import { Img } from "react-image";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ResizeHelper from "../../utils/ResizeHelper";


const SinglePOIFeed = ({  poi, onShowPOI = () => {} }) => {
 

  return (
    <Box
    onClick={() => onShowPOI(poi)}
    sx={{
      cursor: 'pointer',
      overflow: 'hidden',
      flexShrink: 0,
      position: 'relative',
      borderBottomLeftRadius:"4px",
      borderBottomRightRadius:"4px",
      borderTopLeftRadius:"4px",
      borderTopRightRadius:"4px",
      border: "1px solid #dcdfe4",
    
      flexBasis: {
        xs: "100%",
        xs: "100%",
        md: 300,
        lg: 300,
      },
    }}
    key={poi.key}
  >
      <Box sx={{
        position: "relative",
        width: "100%",
        aspectRatio: {
          xs: "1/1",
          md: "16/9",
        },

        backgroundColor: "#1a1a1b",
      }}>

   
      {poi?.thumbnail !== null && (
     
        <LazyLoadImage
        key={poi.googlePlaceId}
         src={ResizeHelper.resizeImage(poi?.thumbnail, 600)}
        alt={poi.name || ""}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          objectPosition: "center",
        }}
       
        className="moment-thumbnail"
          />
      )}
      {poi?.thumbnail === null && (
        <Box
          style={{
            position: "relative",
            flex: 1,
            width: "100%",
            borderRadius: "8px",
            height: "100%",
            width: "100%",
            backgroundColor: "#1a1a1b",
          }}
        />
      )}
         </Box>
      <Box sx={{
        pt:1,
        pl:1.5,
        pr:1.5,
        pb:1,
      }}>
        <Typography sx={{
            whiteSpace: "pre-line",
            textTransform: "uppercase",
            color:"#63687a",
            pb:0.5
        }} 
       
        className="poi-feed poi-name" >
          
          {poi.name}
        </Typography>
        <Typography
      
        sx={{
            whiteSpace: "pre-line",
        }} className="poi-feed poi-teaser"  >
          {poi.teaser}
        </Typography>
      </Box>
    </Box>
  );
};

export default SinglePOIFeed;
