import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import QuestionSlider from "./QuestionSlider";
import { useScrollToBottom } from "./use-scroll-to-bottom";
export const PersonalizeRegionModal = ({}) => {
  const partnerId = window.urbnups.partnerId;
  const language = window.urbnups.language;
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [modalOpen, setModalOpen] = useState(false);
  const [regionId, setRegionId] = useState(null);

  const onClose = () => {
    setModalOpen(false);
    //setPlaceId(null);
  };

  const handlePopState = (event) => {
    //alert("handlePopState");
    setModalOpen(false);
  };

  useEffect(() => {
    window.addEventListener("message", (ev) => {
      const event = ev.data;

      if (event.type === "urbnups-show-region-personalization") {
        console.log("urbnups-show-region-personalization", event.data);

        // setPlaceId(event.data?.placeId);

        setRegionId(event.data?.key);
        setModalOpen(true);
        window.addEventListener("popstate", handlePopState);
      }
    });

    return () => {
      window.removeEventListener("message", () => {});
    };
  }, []);
  const [messagesContainerRef, messagesEndRef] = useScrollToBottom();
  return (
    <Dialog
      open={modalOpen}
      //     fullScreen={!isDesktop}

      maxWidth="md"
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogContent
        ref={messagesContainerRef}
        sx={{
          //width: isDesktop ? "900px" : "100%",
          //height: isDesktop ? "900px" : "100%",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          m: 0,
          p: 0,
        }}
      >
        {" "}
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            position: "absolute",

            p: 1,
            zIndex: 100,
            //align vertical
          }}
        >
          {onClose && (
            <Box>
              <IconButton className="urbnups-backButton" onClick={onClose}>
                <ArrowBackIosNewIcon />
              </IconButton>
            </Box>
          )}
        </Box>
        <Typography
          variant="h3"
          sx={{ color: "black", whiteSpace: "normal", textAlign: "center" }}
        >
          Personalisierung
        </Typography>
        <QuestionSlider
          regionId={regionId}
          partnerId={partnerId}
          language={language}
        />
        <div
          ref={messagesEndRef}
          className="flex-shrink-0 min-w-[24px] min-h-[24px]"
        />
      </DialogContent>
    </Dialog>
  );
};
