import { useFetch } from "use-http";
import SingleMomentFeed from "../../moment/SingleMomentFeed";
import { Box, CircularProgress } from "@mui/material";
import { useEffect, useRef, useState } from "react";

export const RegionFeed = ({
  partnerid,
  regionid,
  language,
  currentCategoryId,
}) => {
  const [lastMomentId, setLastMomentId] = useState(null);

  const {
    loading,
    get,
    error,
    data = { moments: [] },
  } = useFetch(
    `https://urbnups.com/widgets.api/region/${regionid}/moments?lang=${language}&partnerId=${partnerid}&regionid=${regionid}${
      currentCategoryId != null ? `&categoryId=${currentCategoryId}` : ""
    }`,
    {
      onNewData: (currData, newData) => {
        console.log("onNewData", currData, newData);
        var previousMoments = currData?.moments || [];
        return {
          moments: [...previousMoments, ...newData.moments],
          hasMore: newData.hasMore,
          lastMomentId: newData.lastMomentId,
        };
      }, // appends newly fetched todos
      perPage: 0, // stops making more requests if last todos fetched < 15
    },
    [regionid, language, partnerid, currentCategoryId]
  );

  const [isFetchingNextPage, setIsFetchingNextPage] = useState(false);
  const observerRef = useRef(null);
  console.log("data", data);

  const fetchNextPage = async () => {
    console.log("fetchNextPage " + data.lastMomentId);
    if (isFetchingNextPage) {
      return;
    }

    setIsFetchingNextPage(true);
    await get(
      `https://urbnups.com/widgets.api/region/${regionid}/moments?lang=${language}&partnerId=${partnerid}&regionid=${regionid}${
        currentCategoryId != null ? `&categoryId=${currentCategoryId}` : ""
      }${data.lastMomentId != null ? `&lastMomentId=${data.lastMomentId}` : ""}`
    );
    setIsFetchingNextPage(false);
    // setIsFetchingNextPage(true);
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      console.log("entries", entries);
      if (
        entries.some((entry) => entry.isIntersecting) &&
        data?.hasMore &&
        !isFetchingNextPage
      ) {
        fetchNextPage();
      }
    });
    if (observerRef.current) {
      observer.observe(observerRef.current);
    }
    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [observerRef, data, isFetchingNextPage]);

  return (
    <>
      {error && "Error!"}

      {!loading && data && data.moments.length === 0 && "No moments found"}
      {data && data.moments != null && data.moments.length > 0 && (
        <Box>
          {data?.moments.map((moment) => (
            <SingleMomentFeed key={moment.key} moment={moment} />
          ))}
          <Box
            ref={observerRef}
            sx={{ display: "flex", justifyContent: "center", py: 2 }}
          ></Box>
          {loading ||
            (isFetchingNextPage && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
           <CircularProgress className='urbnups-loadingindicator'/>
              </Box>
            ))}
        </Box>
      )}
    </>
  );
};
