import { Box, Typography } from "@mui/material";
import { Img } from "react-image";
import ResizeHelper from "../../utils/ResizeHelper";

const SingleRegionSlider = ({  region,  onShowRegion = () => {} }) => {


  return (
    <Box
      onClick={() => onShowRegion(region)}
    
      sx={{
        cursor: "pointer",
        aspectRatio: 1/1.12,
    flexShrink: 0,
    position: "relative",
        width:{
          xs:"50%",
          sm:"50%",
          md:"25%",
          lg:"12.5%"
        }
      }}
      key={region.key}
      id={region.key}
    >
      {region?.thumbnail !== null && (
        <Img
          src={`${ResizeHelper.resizeImage(region?.thumbnail,900)}`}
          alt={region.name || ""}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "center",
          }}
          className="region-thumbnail"
        />
      )}
      {region?.thumbnail === null && (
        <Box
          style={{
            position: "relative",
            flex: 1,
            width: "100%",

            width: "100%",
            backgroundColor: "#1a1a1b",
          }}
        />
      )}
      <Box className="region-name-container">
        <Typography className="region-name" variant="h6" sx={{ padding: 1 }}>
          {region.geoContexts.de.city}
        </Typography>
      </Box>
    </Box>
  );
};

export default SingleRegionSlider;
