import r2wc from "@r2wc/react-to-web-component";
import './i18n';
import { RudderAnalytics } from "@rudderstack/analytics-js";
import ChatWidgetApp from "./ChatWidgetApp";
import { RegionFeedApp } from "./RegionFeedApp";
import { RegionMomentsSliderApp } from "./RegionMomentsSliderApp";
import { SingleMomentModal } from "./SingleMomentModal";
import { SinglePlaceModal } from "./SinglePlaceModal";
const ChatWidget = r2wc(ChatWidgetApp, {
  props: {
    partnerid: "string",
    regionid: "string",
    language: "string",
    logo: "string",
  },
});
if (window?.urbnupsanalytics == undefined) {
  const analyticsInstance = new RudderAnalytics();

  analyticsInstance.load(
    "2ZAIONkTxs1iTl9AIRcbmug35Jj",
    "https://urbnupsjohoyu.dataplane.rudderstack.com"
  );

  analyticsInstance.ready(() => {
    console.log("urbnups widget Analytics We are all set!!!");
    window.urbnupsanalytics = analyticsInstance;
    window.urbnups.userId=analyticsInstance.getAnonymousId();
    customElements.define("urbnups-chatwidget", ChatWidget);
  });
}




const RegionMomentsSlider = r2wc(RegionMomentsSliderApp, {
  props: {
    partnerid: "string",
    regionid: "string",
    headline: "string",
    subline: "string",
    morelabel: "string",
    morelink: "string",
  },
});
customElements.define("urbnups-region-momentsslider", RegionMomentsSlider);

const RegionFeed = r2wc(RegionFeedApp, {
  props: {
    partnerid: "string",
    regionid: "string",
    language: "string",
    headline: "string",
    subline: "string",
    morelabel: "string",
    morelink: "string",
  },
});
customElements.define("urbnups-region-feed", RegionFeed);

const SingleMomentModalApp = r2wc(SingleMomentModal, {
  props: {},
});
customElements.define("urbnups-moment-modal", SingleMomentModalApp);

const SinglePlaceModalApp = r2wc(SinglePlaceModal, {
  props: {
    partnerid: "string",
    regionid: "string",
    language: "string",
    headline: "string",
    subline: "string",
    morelabel: "string",
    morelink: "string",
  },
});
customElements.define("urbnups-place-modal", SinglePlaceModalApp);

//add modals to dom
const modalContainer = document.createElement("urbnups-moment-modal");
document.body.appendChild(modalContainer);

const placeModalContainer = document.createElement("urbnups-place-modal");
document.body.appendChild(placeModalContainer);
