import { Button, Link, Typography } from "@mui/material";


const MomentEstablishment = ({ moment,variant="subtitle1", language }) => {
 
 
  const getName=() =>{
    if(moment?.linkedEstablishment!=null&&moment?.linkedEstablishment?.names[language]!=null){
      return (moment?.linkedEstablishment?.names[language])

    }
    else if(moment?.linkedEstablishment!=null&&moment?.linkedEstablishment?.names["en"]!=null){
      return (moment?.linkedEstablishment?.names["en"])
    }
    else if(moment?.linkedEstablishment!=null&&moment?.linkedEstablishment?.names["de"]!=null){
      return (moment?.linkedEstablishment?.names["de"])
    }
    else
    {
      return (null)
    }
  }

  const onShowPlace=()=>{
    console.log("show place")
    var eventData = {
      type: "urbnups-show-place",
      data: {
        placeId:moment?.linkedEstablishment?.googlePlaceId,
        
       placeName: getName()}
      
    }
    window.top.postMessage(eventData, '*');
  }


  const linkedEstablishmentName=getName()
 
  

  if(linkedEstablishmentName==null) return(<></>)
else
  return (
    <Button
    variant="contained"
    
    onClick={onShowPlace}
    sx={{
      
      
      pl:1,
      "& .MuiButton-startIcon": {
        ml:0,
        pl:0,
      },
    }}
    startIcon={
      <img
        src={
          "https://static.urbnups.app/webapp/svg/urbnups_Icon_Standort.svg?updatedAt=1702891129521"
        }
        alt="location icon"
        width={20}
        height={20}
      />
    }
  >
     <Typography variant={variant} sx={{fontWeight:"400" }} >
 {linkedEstablishmentName}</Typography>
   </Button>
  );
};

export default MomentEstablishment;
