import AccessTimeIcon from "@mui/icons-material/AccessTime";
import EmailIcon from "@mui/icons-material/Email";
import LanguageIcon from "@mui/icons-material/Language";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import { Box, Divider, IconButton, Link, Typography } from "@mui/material";
import React from "react";
import LinkButton from "../buttons/LinkButton";
import PlaceMiniMap from "../maps/PlaceMiniMap";
import OpeningHours from "../openinghours/OpeningHours";

const placeInfo = ({ place }) => {
  return (
    <>
      <PlaceMiniMap  place={place} />
      <Box p={2}>
        {/* Address */
        
        place?.formattedAddress && (
    <>
        <Box display="flex" alignItems="center" mb={2}>
          <IconButton>
            <LocationOnIcon color="primary" />
          </IconButton>
          <Typography variant="body1">{place?.formattedAddress}</Typography>
        </Box>
        <Divider sx={{ my: 2 }} /></>
        )      }

         {/* Öffnungszeiten (Opening Times) */
        
        place?.openingHours && (
   <>
            <Box display="flex" alignItems="center" mb={2}>
              <IconButton>
                <AccessTimeIcon color="primary" />
              </IconButton>
              <OpeningHours
                headline={"Reguläre Öffnungszeiten"}
                openingHours={place?.openingHours}
              />
            </Box>
                    <Divider sx={{ my: 2 }} /></>
                 )}
        {/* Phone */
        
        place?.phone && (
  <>
        <Box display="flex" alignItems="center" mb={2}>
          <IconButton>
            <PhoneIcon color="primary" />
          </IconButton>
          <Typography variant="body1">
            <Link href="tel:+4315871417" underline="none">
              {place?.phone}
            </Link>
          </Typography>
        </Box>
          <Divider sx={{ my: 2 }} /></>
        )      }
      
        {/* Website */}
        {place?.links != null &&
        
          place?.links.filter((link)=>link!=null&&link!="").map((link, index) => (
            <Box display="flex" alignItems="center" mb={2}>
              <IconButton>
                <LanguageIcon color="primary" />
              </IconButton>
              <LinkButton key={index} url={link} />
            </Box>
          ))}
             {place?.links != null &&
        <Divider sx={{ my: 2 }} />}
        {/* Email */
        place?.email && (
   <>
   

        <Box display="flex" alignItems="center" mb={2}>
          <IconButton>
            <EmailIcon color="primary" />
          </IconButton>
          <Typography variant="body1">
            <Link href={"mailto:"+place?.email} underline="none">
              {place?.email}
            </Link>
          </Typography>
        </Box>
        <Divider sx={{ my: 2 }} />
        </>
        )     }

     

       
      </Box>{" "}
    </>
  );
};

export default placeInfo;
