"use client";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useState } from "react";
import { useTranslation } from "react-i18next";
const { setHours, setMinutes, addDays } = require("date-fns");
const getNextOpeningHours = (openingHours) => {
  try {
    // Get current date and time
    let now = new Date();
    const dayNames = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    let currentDayIndex = now.getDay();
    let currentTime = new Date();

    // Check from current day and time onwards

    const currentDayName = dayNames[currentDayIndex];
    const todayOpeningTimes = openingHours[currentDayName];
    console.log("todayOpeningTimes", todayOpeningTimes);
    if (todayOpeningTimes && todayOpeningTimes.length > 0) {
      // Sort opening times to ensure they are in correct order
      todayOpeningTimes.sort((a, b) => a.open.localeCompare(b.open));
      for (let i = 0; i < todayOpeningTimes.length; i++) {
        var { open, close } = todayOpeningTimes[i];
        console.log("opening times", todayOpeningTimes[i]);

        open = open.split(":");
        close = close.split(":");

        console.log("get status");
        console.log(todayOpeningTimes[i]);
        console.log("open", open[0], open[1]);

        var openTime = setHours(new Date(), parseInt(open[0]));
        openTime = setMinutes(openTime, parseInt(open[1]));

        var closeTime = setHours(new Date(), parseInt(close[0]));
        closeTime = setMinutes(closeTime, parseInt(close[1]));

        // closeTime.set("hour", parseInt(close[0]));
        // closeTime.set("minute", parseInt(close[1]));
        console.log("closeTime", closeTime);
        if (closeTime < openTime) {
          closeTime.add(1, "day");
        }

        if (currentTime <= openTime) {
          // Found the next opening hours
          console.log("Next open", todayOpeningTimes[i]);
          return {
            day: currentDayName,
            open: todayOpeningTimes[i].open,
            close,
          };
        }
      }
    }
    return null;
  } catch (e) {
    console.log(e);
    return null;
  }
};

const getStatus = (openingHours) => {
  if (openingHours == null || openingHours == null) {
    return null;
  }
  console.log("get status openingHours", openingHours);
  // Get current date and time
  const now = new Date();
  const currentDay = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ][now.getDay()];

  var currentTime = new Date();

  // Get opening times for today
  const todayOpeningTimes = openingHours[currentDay];

  console.log("get statsus openingHours", openingHours);
  // Check if there are opening times

  if (!todayOpeningTimes || todayOpeningTimes.length === 0) {
    return {
      open: false,
      nextOpen: getNextOpeningHours(openingHours),
    }; // Closed today
  }

  // Check if now is within any of the opening times
  for (let i = 0; i < todayOpeningTimes.length; i++) {
    var { open, close } = todayOpeningTimes[i];

    open = open.split(":");
    close = close.split(":");

    console.log("get status");
    console.log(todayOpeningTimes[i]);
    console.log("open", open[0], open[1]);

    var openTime = setHours(new Date(), parseInt(open[0]));
    openTime = setMinutes(openTime, parseInt(open[1]));

    //set(openTime,{hour:open[0],minute:open[1]})

    var closeTime = setHours(new Date(), parseInt(close[0]));
    closeTime = setMinutes(closeTime, parseInt(close[1]));
    // closeTime.set("hour", close[0]);
    // closeTime.set("minute", close[1]);

    if (closeTime < openTime) {
      closeTime = addDays(closeTime, 1);
    }

    if (openTime <= currentTime && closeTime >= currentTime) {
      console.log("Currently open" + open + " " + close);
      return {
        open: true,
        closes: todayOpeningTimes[i].close,
      };
    }
  }

  return {
    open: false,
    nextOpen: getNextOpeningHours(openingHours),
  };
};
function OpeningHours({
  openingHours = null,
  showCurrentStatus = false,
  headline = null,
}) {
  console.log("openingHours", openingHours);
  const { t } = useTranslation();

  // ... (Keep your logic functions like getNextOpeningHours and getStatus)
  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const date = new Date();
  const currentDayIndex = date.getDay(); // getDay() returns 0 for Sunday, 1 for Monday, etc.
  const currentStatus = getStatus(openingHours);
  const [showFullHours, setShowFullHours] = useState(false);
  const reorderedDayNames = dayNames
    .slice(currentDayIndex)
    .concat(dayNames.slice(0, currentDayIndex));

  console.log(" server business_openinghours", openingHours);
  const renderOpeningHoursDay = (day, index) => {
    if (!openingHours) {
      return null;
    }
    var openings = openingHours[day];

    if (!openings) {
      openings = [];
    }

    return (
      <Box
        key={"opendinghours" + day}
        sx={{
          display: "flex",
          flexDirection: "row",
          paddingBottom: 1,
        }}
      >
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: index === 0 ? "bold" : "normal",
          }}
        >
          {t("opening_hours." + day.toUpperCase())}
        </Typography>
        <Box sx={{ paddingLeft: 1 }}>
          <Typography sx={{ fontSize: 14 }}>
            {openings.length > 0
              ? openings.map((item, idx) => {
                  return (
                    <span key={idx}>
                      {item?.open} - {item?.close}
                      {idx < openings.length - 1 ? "\n" : ""}
                    </span>
                  );
                })
              : t("opening_hours.closed")}
          </Typography>
        </Box>
      </Box>
    );
  };
  if (currentStatus != null)
    return (
      <Box
       
      >
        <Box  sx={{
          display: "flex",
          flexDirection: "row",
          //space between
          justifyContent: "space-between",
        }}>
          {headline && (
            <Typography
              onClick={() => setShowFullHours(!showFullHours)}
              sx={{}}
            >
              {headline}
            </Typography>
            //dropdown icon here
          )}
          <Box sx={{
            marginLeft: "auto",
          }}>
            {showFullHours ? (
              <KeyboardArrowUpIcon sx={{ ml: 1 }} />
            ) : (
              <KeyboardArrowDownIcon sx={{ ml: 1 }} />
            )}
          </Box>
        </Box>
        {showCurrentStatus && (
          <Box
            onClick={() => setShowFullHours(!showFullHours)}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              pb: 2,
              cursor: "pointer",
            }}
          >
            <AccessTimeIcon sx={{ mr: 1 }} />
            <Box sx={{ justifyContent: "center" }}>
              {currentStatus?.open ? (
                <Typography variant="subtitle1" sx={{ color: "#89d856" }}>
                  {t("opening_hours.open")}{" "}
                  {currentStatus?.closes && (
                    <span>
                      · {t("opening_hours.isClosingAt")} {currentStatus?.closes}
                    </span>
                  )}
                </Typography>
              ) : (
                <Typography variant="subtitle1" sx={{ color: "red" }}>
                  {t("opening_hours.closed")}{" "}
                  {currentStatus?.nextOpen && (
                    <span>
                      · {t("opening_hours.open_at")}{" "}
                      {currentStatus?.nextOpen?.open}
                    </span>
                  )}
                </Typography>
              )}
            </Box>
            {showFullHours ? (
              <KeyboardArrowUpIcon sx={{ ml: 1, color: "white" }} />
            ) : (
              <KeyboardArrowDownIcon sx={{ ml: 1, color: "white" }} />
            )}
          </Box>
        )}
        {showFullHours &&
          reorderedDayNames.map((day, index) => {
            return renderOpeningHoursDay(day, index);
          })}
      </Box>
    );
  else {
    return <></>;
  }
}

export default OpeningHours;
