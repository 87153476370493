import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useFetch } from "use-http";
import LikeButton from "./components/buttons/LikeButton";
import POIMediaSlider from "./components/mediaslider/POIMediaSlider";
import POIDescription from "./components/poi/POIDescription";
import POIHighlightsSlider from "./components/poi/POIHighlightsSlider";
import POIInfo from "./components/poi/POIInfo";
import POIQuestions from "./components/poi/POIQuestions";
import POIEventsSlider from "./components/poi/POIEventsSlider";

export const SinglePlaceModal = ({}) => {
  const partnerId = window.urbnups.partnerId;
  const language = window.urbnups.language;
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [modalOpen, setModalOpen] = useState(false);
  const [place, setPlace] = useState(null);
  const [zIndex, setZIndex] = useState(111111001);

  const { get, loading, data, error } = useFetch(
    "https://urbnups.com/widgets.api/place"
  );

  const onClose = () => {
    setModalOpen(false);
    //setPlaceId(null);
  };

  useEffect(() => {
    if (data) {
      console.log("data", data);
      setPlace(data);
    }
  }, [data]);

  const handlePopState = (event) => {
    //alert("handlePopState");
    setModalOpen(false);
  };

  useEffect(() => {
    window.addEventListener("message", (ev) => {
      const event = ev.data;

      if (event.type === "urbnups-show-place") {
        console.log("receive urbnups-show-place", event.data);
        setZIndex(window.urbnups.maxZIndex || 111111001 + 1);
        window.urbnups.maxZIndex = (window.urbnups?.maxZIndex || 111111001) + 1;
        // setPlaceId(event.data?.placeId);
        setPlace(null);
        window.history.pushState({ modalOpen: true }, "", window.location.href);
        get(
          `${event.data?.googlePlaceId}?lang=${language}&partnerId=${partnerId}`
        );
        setModalOpen(true);
        window.addEventListener("popstate", handlePopState);
      }
    });

    return () => {
      window.removeEventListener("message", () => {});
    };
  }, []);

  console.log("fetched place", place);
  console.log("data?.place_id}", place?.place_id);
  return (
    <Dialog
      open={modalOpen}
      fullScreen={!isDesktop}
      maxWidth="md"
      sx={{ zIndex: zIndex }}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogContent
        sx={{
          p: 0,
          width: isDesktop ? "900px" : "100%",
          height: isDesktop ? "900px" : "100%",
          flexGrow: 1,
        }}
      >
        {" "}
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            position: "absolute",

            p: 1,
            zIndex: 100,
            //align vertical
          }}
        >
          {onClose && (
            <Box>
              <IconButton className="urbnups-backButton" onClick={onClose}>
                <ArrowBackIosNewIcon />
              </IconButton>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            position: "absolute",
            right: 10,
            p: 1,
            zIndex: 100,
            //align vertical
          }}
        >
          <LikeButton placeId={place?.place_id} />
        </Box>
        {loading && (
          <>
            <Box
              sx={{
                padding: 10,
              }}
            >
              <CircularProgress className="urbnups-loadingindicator" />
            </Box>
          </>
        )}
        {error && "Error!"}
        {!loading && !error && place != null && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <POIMediaSlider poiName={place?.name} media={place?.media} />

            <POIDescription place={place} />

            <POIHighlightsSlider place={place} />
            <POIEventsSlider place={place} />
            <POIInfo place={place} />
            <POIQuestions place={place} />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};
