import { Box, Button, Typography } from "@mui/material";

const CategorySlider = ({
  categories = [],
  setCurrentCategoryId = {},
  currentCategoryId = null,
}) => {
  const onCategoryClick = (category) => {
    setCurrentCategoryId(category.id);
  };
  const isActiveCategory = (category) => {
    return currentCategoryId === category.id;
  };

  if (categories == null) return <></>;
  else
    return (
      <Box
        sx={{
          mt: 2,
          mb: 3,
        }}
      >
        <Typography variant="h3" sx={{ fontWeight: 600, color: "black" }}>
          Categories
        </Typography>
        <Box
          sx={{
            pl: {
              xs: 2,
              md: 0,
            },
          }}
        ></Box>
        {categories.map((category, index) => (
          <Button
          className={isActiveCategory(category) ? "urbnups-category-button-active" : "urbnups-category-button-inactive"}
            variant="contained"
            key={category?.id || index}
            startIcon={
              <img
                alt={category?.name}
                src={category.iconUrl}
                width={15}
                height={15}
              />
            }
            // variant={selectedCategories.includes(category.id) ? 'contained' : 'text'}
            //color={selectedCategories.includes(category.id) ? 'secondary' : 'inherit'}
            //startIcon={<IconButton>{category.icon}</IconButton>}
            //onClick={() => onSelect(category.id)}
            onClick={() => onCategoryClick(category)}
            sx={{
              borderRadius: 10,
              ml: index > 0 ? 1 : 0,
              pl: 2,
              pr: 2,
              pt: 0.75,
              pb: 0.75,
              textTransform: "none",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              minWidth: "auto",
              wordWrap: "none",
        
            }}
          >
            <Typography
              variant="body"
              sx={{
                fontWeight: isActiveCategory(category) ? 600 : 400,
              }}
            >
              {category.name}
            </Typography>
          </Button>
        ))}
        <Box
          sx={{
            pr: {
              xs: 2,
              md: 0,
            },
          }}
        ></Box>
      </Box>
    );
};

export default CategorySlider;
