
import { useTheme } from "@emotion/react";
import { Box, Button, useMediaQuery } from "@mui/material";
import { Img } from "react-image";

const PlaceMiniMapShowMapButton = ({ place, title = "Show Map" }) => {
  const theme = useTheme();


  const showLocation = () => {
    /*
    analytics?.track("show_spot_on_map", {
      spotId: place?.key,
      spot: moment,
    });
*/
    if (place?.location?.lat != null && place?.location?.long != null) {
      var link =
        "https://www.google.com/maps/search/?api=1&query=" +
        place?.location.lat +
        "," +
        place.location.long;
      if (true) window.open(link, "_blank");
      else window.open(link, "_self");
    }
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        
      
      }}
    >
      <Button
        startIcon={
          <Img
            src="https://static.urbnups.app/webapp/svg/urbnups_Icon_Navi_AhMUXyXYY.svg?updatedAt=1701432343400"
            width={20}
            height={20}
            alt="navigation icon"
          />
        }
        onClick={() => {
          showLocation();
        }}
        variant="contained"
        color="primary"
        sx={{
          color: "white",
          boxShadow: "none",
          marginTop: 1,
          marginLeft:1,
          borderColor: "white",
          backgroundColor: "rgba(0,0,0,0.7)",
        }}
      >
        {title}
      </Button>
    </Box>
  );
};

export default PlaceMiniMapShowMapButton;
