import { Button, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

export const LikeButton = ({ url }) => {
  //  const [open, setOpen] = useState(false);

  const handleOpen = (event) => {
    event.preventDefault();
    window.open(url, "_blank");
    //setOpen(true);
  };

  // const handleClose = () => setOpen(false);

  return (
    <Button
    variant="contained"
    className="urbnups-likeButton"
  
    >
      <FavoriteBorderIcon />
      <Typography variant="body2" sx={{ color: "white",ml:1 }}>
        Merken
      </Typography>
    </Button>
  );
};

export default LikeButton;
