"use client";
import { Button, ClickAwayListener, Tooltip, Typography } from "@mui/material";
import { useState } from "react";

export default function CopyrightTooltip({
    text,   
  children,
  sx = {},
  ...props
}) {
    const [open, setOpen] = useState(false);
    const handleTooltipClose = () => {
      setOpen(false);
    };
    const handleTooltipOpen = () => {
        setOpen(true);
        }
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
    <div>
    <Tooltip
    componentsProps={{
        tooltip: {
          sx: {
            bgcolor: 'common.black',
            '& .MuiTooltip-arrow': {
              color: 'white',
            },
          },
        },
      }}
      title={
        <Typography variant="body2" sx={{ color: "white" }}>
          {text}
        </Typography>
      }
      {...props}
      PopperProps={{
        disablePortal: true,
      }}
      onClose={handleTooltipClose}
      open={open}
      disableFocusListener
      disableHoverListener
      disableTouchListener
    >
        <Button onClick={handleTooltipOpen} sx={{color:"white",...sx}}>©
        </Button>
      
    </Tooltip></div>
    </ClickAwayListener>
  );
}   