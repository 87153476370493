import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { useEffect, useState } from "react";
import RegionOverview from "./components/region/overview/RegionOverview";
const options = {}; // these options accept all native `fetch` options
export const RegionModal = ({}) => {
  const partnerId = window.urbnups.partnerId;
  const language = window.urbnups.language;
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [modalOpen, setModalOpen] = useState(false);
  const [place, setPlace] = useState(null);
  const [regionId, setRegionId] = useState(null);
  const [regionCategory, setRegionCategory] = useState("explore");
  const onClose = () => {
    setModalOpen(false);
    //setPlaceId(null);
  };


  const handlePopState = (event) => {
    //alert("handlePopState");
    setModalOpen(false);
  };

  useEffect(() => {
    window.addEventListener("message", (ev) => {
      const event = ev.data;

      if (event.type === "urbnups-show-region") {
        console.log("urbnups-show-region", event.data);

        // setPlaceId(event.data?.placeId);
        setPlace(null);
        window.history.pushState({ modalOpen: true }, "", window.location.href);
        setRegionId(event.data?.key);
        setModalOpen(true);
        window.addEventListener("popstate", handlePopState);
      }
    });

    return () => {
      window.removeEventListener("message", () => {});
    };
  }, []);

 
  return (
    <Dialog
      open={modalOpen}
 //     fullScreen={!isDesktop}
 fullScreen={true}
      //maxWidth="md"
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogContent
        sx={{
          p: 0,
          //width: isDesktop ? "900px" : "100%",
          //height: isDesktop ? "900px" : "100%",
          flexGrow: 1,
        }}
      >
        {" "}
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            position: "absolute",

            p: 1,
            zIndex: 100,
            //align vertical
          }}
        >
          {onClose && (
            <Box>
              <IconButton className="urbnups-backButton" onClick={onClose}>
                <ArrowBackIosNewIcon />
              </IconButton>
            </Box>
          )}
        </Box>
        <RegionOverview regionid={regionId} />
      </DialogContent>
    </Dialog>
  );
};
