import { Box } from "@mui/material";

import CategorySlider from "./components/categories/CategorySlider";
import { useState } from "react";
import { RegionFeed } from "./components/region/feed/RegionFeed";

export const RegionFeedApp = ({
  partnerid,
  regionid,
  language,
  headline,
  subline,
  morelabel,
  morelink,
}) => {
  const options = {}; // these options accept all native `fetch` options
  // the last argument below [] means it will fire onMount (GET by default)
  const categories = [
    {
      id: null,
      name: "Angesagt",
      iconUrl: "/images/icons/all.svg",
    },
    {
      id: "0GdG43tRqzEhQfDTDqW4",
      name: "Family Time",
      iconUrl:
        "https://firebasestorage.googleapis.com/v0/b/urbnups.appspot.com/o/categories%2Fneu%2Furbnups_Family_List.png?alt=media&token=7b4c7299-fe78-45b0-b3cf-be9bb8eb7c95",
    },
  ];

  const [currentCategoryId, setCurrentCategoryId] = useState(null);

  return (
    <Box>
      <CategorySlider
        currentCategoryId={currentCategoryId}
        setCurrentCategoryId={setCurrentCategoryId}
        categories={categories}
      />
      <RegionFeed
        partnerid={partnerid}
        regionid={regionid}
        language={language}
        currentCategoryId={currentCategoryId}
      />
    </Box>
  );
};
