import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import React from "react";
import { Img } from "react-image";
import ResizeHelper from "../../../utils/ResizeHelper";
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: 'white',
  },
});



const StyledTab = styled((props) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  color: 'rgba(255, 255, 255, 0.7)',
  '&.Mui-selected': {
    color: '#fff',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
  color: "white",
            
  "&.Mui-selected": {
    color: "white",
    backgroundColor: "#2f534f",
    transition: "background-color 0.5s",
    borderTopRightRadius: 6,
    borderTopLeftRadius: 6,
  },
}));
const RegionBanner = ({
  region,
  categories,
  selectedCategory,
  setSelectedCategory,
}) => {
  const onCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  return (
    <Box
    className="urbnups-region-banner"
      sx={{
        position: "relative",
        overflow: "hidden",
        height: {
          xs:360,
          
            md:460,
          
        },
        backgroundImage: `url(${ResizeHelper.resizeImage(region.banner?.image?.url,900)})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
      }}
    >
      <Box
        sx={{
          width: "100%",
          position: "absolute",
          bottom: 0,
          background: "linear-gradient(180deg,transparent,rgba(0,0,0,.7))",
        }}
      >
    
     
   <Box
          sx={{
            //  backgroundColor: "rgba(0,0,0,0.38)",

            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 100,
            alignItems: "flex-start",
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "column",
            pl: 2,
            pr: 2,
            pb: 3,
            overflow: "hidden",
            overflowWrap: "break-word",
          }}
        >
          <Typography
            className="urbnups-region-banner-headline"
            sx={{
              hyphens: "auto",
              overflow: "hidden",
              overflowWrap: "break-word",
              wordBreak: "break-word",
              color: "white",
              
            }}
          >{region?.banner?.headline}
          
          </Typography>
          <Typography
            className="urbnups-region-banner-subheadline"
            sx={{
              pt:1,
              hyphens: "auto",
              overflow: "hidden",
              overflowWrap: "break-word",
              wordBreak: "break-word",
              color: "white",
            
            }}
          >{region?.banner?.subheadline}
          </Typography>
        </Box>
            
     
        <StyledTabs
          className="categories-slider"
          value={selectedCategory} // Change this to manage selected tab
          variant="scrollable"
          scrollButtons="auto"
         // allowScrollButtonsMobile
         
          onChange={(event, newValue) => {
            console.log("console log value change", newValue);
            onCategoryClick(newValue);
          }}
        >
          
          <StyledTab
          className="category-tab"
            sx={{
            
            }}
            selected={selectedCategory == "explore"}
            value={"explore"}
            icon={
              <TravelExploreIcon
                style={{
                  width: 24,
                  height: 24,
                }}
              />
            }
            key={"explore"}
            label={"Entdecken"}
          />
            <StyledTab
          className="category-tab"
            sx={{
            
            }}
            selected={selectedCategory == "foryou"}
            value={"foryou"}
            icon={
              <AutoAwesomeIcon
                style={{
                  width: 24,
                  height: 24,
                }}
              />
            }
            key={"foryou"}
            label={"Für dich"}
          />
          {categories.map((category) => (
            <StyledTab
              className="category-tab"
              sx={{
                
               
              }}
              selected={selectedCategory == category.id}
              value={category.id}
              icon={
                <Img
                  style={{
                    width: 24,
                    height: 24,
                  }}
                  src={ResizeHelper.resizeImage(category?.iconUrl, 128)}
                />
              }
              key={category.id}
              label={category.name}
            />
          ))}
        </StyledTabs>
      </Box>
    </Box>
  );
};

export default RegionBanner;
