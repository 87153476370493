import { Box, Typography, IconButton } from "@mui/material";
import React, { useRef, useEffect, useState } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { max } from "date-fns";

function getNextTimeInterval(timeIntervals) {
  const now = new Date();

  // Sort the intervals by start time
  const sortedIntervals = timeIntervals
    .slice()
    .sort((a, b) => new Date(a.start) - new Date(b.start));

  for (const interval of sortedIntervals) {
    const start = new Date(interval.start);
    if (start > now) {
      return interval;
    }
  }

  // If no upcoming interval is found
  return null;
}
function getNextTimeIntervalString(timeIntervals) {
  const interval = getNextTimeInterval(timeIntervals);
  if (interval) {
    //date should be formatted in dd.mm.yyyy format and if it ends on the same day, only the start date should be displayed
    const startDate = new Date(interval.start);
    const endDate = new Date(interval.end);
    if (startDate.getDate() === endDate.getDate()) {
     //format in dd.mm.yyy: hh:mm - hh:mm
      return `${startDate.toLocaleDateString()} ${startDate.toLocaleTimeString()} - ${endDate.toLocaleTimeString()}`;
    }
    else
    {
      //format in dd.mm.yyy: hh:mm - dd.mm.yyy: hh:mm
      return `${startDate.toLocaleDateString()} ${startDate.toLocaleTimeString()} - ${endDate.toLocaleDateString()} ${endDate.toLocaleTimeString()}`;
    }
  }
  return "";
}


const POIEventsSlider = ({ place }) => {
  const sliderRef = useRef(null);
  const [isAtStart, setIsAtStart] = useState(true); // Track if slider is at start
  const [isAtEnd, setIsAtEnd] = useState(false); // Track if slider is at end

  useEffect(() => {
    const checkForOverflow = () => {
      if (sliderRef.current) {
        const { scrollWidth, clientWidth } = sliderRef.current;
        setIsAtEnd(sliderRef.current.scrollLeft + clientWidth >= scrollWidth);
        setIsAtStart(sliderRef.current.scrollLeft === 0);
      }
    };

    checkForOverflow();
    window.addEventListener("resize", checkForOverflow);
    return () => {
      window.removeEventListener("resize", checkForOverflow);
    };
  }, [place?.events]);

  const handleScroll = () => {
    if (sliderRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = sliderRef.current;
      setIsAtEnd(scrollLeft + clientWidth >= scrollWidth); // Update end state
      setIsAtStart(scrollLeft === 0); // Update start state
    }
  };

  const scrollLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: -sliderRef.current.clientWidth,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: sliderRef.current.clientWidth,
        behavior: "smooth",
      });
    }
  };

  if (!place?.events || place?.events.length === 0) {
    return null;
  }

  return (
    <Box
      sx={{
        mt: 1,
        position: "relative",
    
        overflowX: "hidden",
        backgroundColor: "#c7354c",
        borderRadius: 4,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        boxShadow: "0px -8px 20px rgba(0, 0, 0, 0.4)",
        padding: 4,
      }}
    >
      <Typography
        variant="h5"
        sx={{ textAlign: "center", flexGrow: 1, pb: 2, color: "white" }}
        component="div"
      >
        Events
      </Typography>

      {/* Left Arrow */}
      <IconButton
        disabled={isAtStart} // Disable button if at the start
        sx={{
          position: "absolute",
          top: "50%",
          left: "10px",
          transform: "translateY(-50%)",
          zIndex: 1,
          opacity: isAtStart ? 0.3 : 1, // Reduce opacity when disabled
          color: "white",
        }}
        onClick={scrollLeft}
      >
        <ArrowBackIosNewIcon sx={{ color: "white", height: 15 }} />
      </IconButton>

      <Box
        ref={sliderRef}
        sx={{
          display: "flex",
          flexDirection: "row",
          overflowX: "auto",
          scrollSnapType: "x mandatory",
          scrollBehavior: "smooth",
          WebkitOverflowScrolling: "touch",
          "&::-webkit-scrollbar": { display: "none" },
          msOverflowStyle: "none",
          scrollbarWidth: "none",
          columnGap: 2,
          width: "100%",
          flexWrap: "nowrap",
        }}
        onScroll={handleScroll}
      >
        {place?.events.map((event, index) => (
          <Box
            key={index}
            sx={{
              cursor: 'pointer',
              overflow: 'hidden',
              flexShrink: 0,
              position: 'relative',
              borderBottomLeftRadius:"4px",
              borderBottomRightRadius:"4px",
              borderTopLeftRadius:"4px",
            borderTopRightRadius:"4px",
            //  border: "1px solid #dcdfe4",
              width: {
                xs: 240,
                sm: 240,
                md: 300,
                lg: 300,
              },
              flexBasis: {
                xs: 240,
                sm: 240,
                md: 300,
                lg: 300,
              },
            }}
          >
        
            {true&& (
              <Box
              sx={{
                position: "relative",
                width: "100%",
                height: {
                  xs: 200,
                  sm: 200,
                  md: 200,
                  lg: 200,
                },
              }}
              >
                {
                  event.thumbnail != null && (
                    <LazyLoadImage
                      src={`${event.thumbnail}`}
                      alt={event.name || ""}
                      quality={60}
                      style={{
                        objectFit: "cover",
                        objectPosition: "center",
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  )
                }
                {
                  event.thumbnail == null && (
                    <Box
                      style={{
                        position: "relative",
                        flex: 1,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "black",
                      }}
                    />
                  )
                }
             
              <Box sx={{
               background: "linear-gradient(180deg,transparent,rgba(0,0,0,.3))",
              position: "absolute",
            height: {
              xs: "auto",
              md: "50%",
            },
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 100,
              alignItems: "flex-start",
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "column",
              pl: 2,
              pr: 2,
              pb: 1,
              overflow: "hidden",
              overflowWrap: "break-word",
              }}>
                  <Typography
            className="urbnups-place-events-eventname"
            >
              {event.name}
            </Typography>
            <Typography
          className="urbnups-place-events-time"
            >
              {getNextTimeIntervalString(event.timeIntervals)}
            </Typography>
            </Box>
              </Box>
            )}
          </Box>
        ))}
      </Box>

      {/* Right Arrow */}
      <IconButton
        disabled={isAtEnd} // Disable button if at the end
        sx={{
          position: "absolute",
          top: "50%",
          right: "10px",
          transform: "translateY(-50%)",
          zIndex: 1,
          opacity: isAtEnd ? 0.3 : 1, // Reduce opacity when disabled
          color: "white",
        }}
        onClick={scrollRight}
      >
        <ArrowForwardIosIcon sx={{ color: "white", height: 15 }} />
      </IconButton>
    </Box>
  );
};

export default POIEventsSlider;