import { Box } from "@mui/material";
import { useState } from "react";
import MediaGalleryItem from "./MediaGalleryItem";
import MediaGalleryModal from "./MediaGalleryModal";
import LinkButton from "../buttons/LinkButton";


const MediaGallery = ({ media }) => {
  const [showMediaGalleryModal, setShowMediaGalleryModal] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(null);
  return (
    <Box>
      <Box sx={{
        width: "100%",
        aspectRatio: "16/9",
        position: "relative",
      }}>
      <MediaGalleryItem  variant="hero" key={"main"} item={media[0]} index={0} />
            
      </Box>
      {media != null && media.length > 1 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            scrollSnapAlign: "center",
            scrollSnapType: "x mandatory",
            mt: "2px",
            overflowX: "auto",
            overflowY: "hidden",
            whiteSpace: "nowrap",
            scrollBehavior: "smooth",
            WebkitOverflowScrolling: "touch",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            msOverflowStyle: "none",
            scrollbarWidth: "2px",

            width: "100%",
            height: "100%",
            justifyContent: "flex-start",
          }}
        >
          {media.map((item, index) => (
            <Box
              sx={{
                width: 80,
                height: 80,
                position: "relative",
                flexShrink: 0,
                mr: "2px",
              }}
            >
              <MediaGalleryItem onClick={
                () => {
                  setShowMediaGalleryModal(true);
                  setSelectedMedia(item);
                }
              } key={index+1} item={item} index={index+1} />
            </Box>
          ))}
        </Box>
      )}
      <MediaGalleryModal media={selectedMedia} show={showMediaGalleryModal} onClose={() => setShowMediaGalleryModal(false)} />
    </Box>
  );
};

export default MediaGallery;
